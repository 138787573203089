@import '../styles/local_variables.css';

:global {
  #root {
    height: 100%;

    .notifications {
      height: 100%;

      .container {
        flex-shrink: 1;
        overflow: hidden;

        .content {
          flex-shrink: 1;
          overflow: hidden;
        }
      }
    }
  }
}

html,
body {
  height: 100%;
}

:global {
  .mapboxgl-map {
    font: inherit;
  }

  .mapboxgl-ctrl-attrib {
    font:
      12px/20px 'Helvetica Neue',
      Arial,
      Helvetica,
      sans-serif;
  }

  .mapboxgl-popup-content {
    border-radius: var(--size-radius-small);
    box-shadow: var(--shadow-elevation-overlay);
  }

  .trimblemaps-map {
    font: inherit;
  }

  .trimblemaps-ctrl-attrib {
    font:
      12px/20px 'Helvetica Neue',
      Arial,
      Helvetica,
      sans-serif;
  }

  .trimblemaps-popup-content {
    border-radius: var(--size-radius-small);
    box-shadow: var(--shadow-elevation-overlay);
  }
}
