@keyframes slideIn {
  from {
    bottom: -200px;
  }
  to {
    bottom: 40px;
  }
}

@keyframes slideInBottom {
  from {
    bottom: -200px;
  }
  to {
    bottom: 0;
  }
}

.popup {
  position: fixed;
  width: 1150px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 40px;
  background: var(--color-neutral-bg-surface-overlay);
  border-radius: var(--size-radius-small);
  color: var(--color-neutral-text-default);
  box-shadow: var(--shadow-elevation-overlay);
  display: flex;
  flex-direction: row;
  z-index: var(--z-index-modal);
  animation-name: slideIn;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  .euFlag {
    flex-shrink: 0;
    margin-left: 40px;
    align-self: center;
  }

  .body {
    display: flex;
    align-items: center;
    padding: 28px 40px;
    box-sizing: border-box;

    button.allow {
      margin-left: 26px;
    }
  }

  button.disallow {
    border: none;
    flex: 0 0 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--color-neutral-border-subtle);
    background: var(--color-neutral-bg-surface-flat);
    border-top-right-radius: var(--size-radius-small);
    border-bottom-right-radius: var(--size-radius-small);
    margin: 0;
  }

  button {
    flex-shrink: 0;
  }
}

@media (--media-width-medium-max) {
  .popup {
    left: 0;
    transform: none;
    width: 100%;
    bottom: 0;
    animation-name: slideInBottom;
    border-radius: 0;

    button.disallow {
      border-radius: 0;
    }

    .body {
      width: auto;
    }
  }
}

@media (--media-width-xsmall-max) {
  .popup {
    flex-direction: column;

    .body {
      flex-direction: column;
    }

    .euFlag {
      display: none;
    }

    button.allow {
      margin-top: 16px;
      margin-left: 0 !important;
    }

    button.disallow {
      border-left: none;
      border-top: 1px solid var(--color-neutral-border-subtle);
    }
  }
}
