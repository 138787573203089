:root {
  --vehicle-management-first-colum-width: 257px;
  --fleet-monitor-first-colum-width: 256px;
  --carrier-report-grid-gutter-size: 8px;
  --vehicle-item-height: 58;
  --integration-header-height: 80px;
  --integration-configuration-footer-height: 72px;
  --integration-configuration-header-height: 68px;
  --widget-width-default: 432;
  --widget-width-small: 344;
  --widget-width-smallest: 282;
  --widget-row-margin: 16;
  --widget-margin: 16;
  --calculated-scrollbar-width: 0px; /* gets calculated in calculateScrollbarWidth() */
}
