.error {
  background-color: var(--color-neutral-bg-surface-sunken);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    font: var(--font-title-xlarge);
  }
}
